<template>
  <Layout ref="main">
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col mb-3" v-if="!showVerifyModule">
        <h1 class="font-size-24 mb-1 animate__animated animate__fadeInLeft">Create an Account</h1>
        <p class="text-muted mb-3 animate__animated animate__fadeInBottom">Create an account to access our exclusive content and services.  </p>
      </div>
      <div class="col mb-0" v-if="showVerifyModule">
        <h1 class="font-size-24 mb-1 animate__animated animate__fadeInLeft">Verification</h1>
        <p class="text-muted mb-3 animate__animated animate__fadeInBottom">Enter the 6-digit verification code that you received. </p>
      </div>
      <div class="col-auto text-end">
          <div class="input-group d-inline-block mb-0">
            <b-dropdown variant="light" right class="bg-white border shadow-none">
              <template v-slot:button-content>
                <img class="me-1" :src="selectedLang.flag" :alt="selectedLang.title" height="16" />
                {{ selectedLang.title }}
              </template>
              <b-dropdown-item
                class="notify-item"
                v-for="(value, i) in languages"
                :key="`Lang${i}`"
                :value="value"
                @click="setLanguage(value)"
              >
                <img
                  :src="`${value.flag}`"
                  alt="user-image"
                  class="me-1"
                  height="12"
                />
                <span class="align-middle"> {{ value.title }}</span>
              </b-dropdown-item>
            </b-dropdown>
            
          </div>
      </div>
      <div class="col-12" v-show="!showVerifyModule">
        <div class="form-group mb-3">
          <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"><i class="mdi mdi-cellphone-iphone me-1 font-size-16"></i> {{$t('siteLang.PhoneNumber')}}</label>
          <div class="input-group mb-3">
          <vue-country-code
              class="input-group-text bg-none p-0 font-size-15"
              @onSelect="onSelect"
              :enabledCountryCode="true"
              :preferredCountries="['my','sg', 'th', 'vn','id']">
            </vue-country-code>
            <input type="tel" class="form-control form-control-lg bg-none" placeholder="Phone No."  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')" v-model="phNo" @input="phoneChange"
            :class="{
                'is-invalid rounded': submitted && $v.formData.phone.$error,
            }">
            <div
            v-if="submitted && $v.formData.phone.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.formData.phone.required"
              >{{$t('siteLang.PhoneNumber')}} {{$t('siteLang.isRequired')}}</span
            >
            <span v-if="!$v.formData.phone.countryCodeAndPhone">
              Please enter a valid phone number between 8 and 15 digits
            </span>
          
          </div>
          </div>
          
          
        </div>
        <div class="form-group mb-3">
          <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"><i class="mdi mdi-lock-outline me-1 font-size-16"></i> {{ $t('siteLang.Password') }}</label>
          <div class="input-group">
              <input :type="passwordFieldType" class="form-control form-control-lg bg-none"  :placeholder="$t('siteLang.Password')"
              v-model.trim="formData.password"
              :class="{
                'is-invalid': submitted && $v.formData.password.$error,
              }"
              >
              <span class="input-group-text bg-none"  @click="togglePasswordFieldType">
                <i class="mdi" :class="{'mdi-eye-off': !showPassword , ' mdi-eye':showPassword }"></i>
              </span>
              <div
                v-if="submitted && $v.formData.password.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.formData.password.required"
                  >{{ $t('siteLang.Password') }} {{$t('siteLang.isRequired')}}</span
                >
              </div>
          </div>
          
        </div>
        <div class="form-group mb-3">
          <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"><i class="mdi mdi-share-variant me-1 font-size-16"></i> 
           {{$t('siteLang.ReferralCode')}} </label>
            <div class="input-group">
                <input type="tel" class="form-control form-control-lg bg-none" :placeholder="$t('siteLang.ReferralCode')"
                oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');" maxlength="6"
                v-model.trim="formData.referralCode" 
                :class="{
                  'is-invalid': submitted && $v.formData.referralCode.$error,
                }"
                >
                <span class="input-group-text bg-none" @click="scanQR">
                  <i class="mdi mdi-qrcode-scan"></i>
                </span>
                <div
                  v-if="submitted && $v.formData.referralCode.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.formData.referralCode.required"
                    >{{$t('siteLang.ReferralCode')}} {{$t('siteLang.isRequired')}}</span
                  >
                  <span v-if="!$v.formData.referralCode.minLength">{{$t('siteLang.ReferralCode')}} {{$t('siteLang.mustHaveAtLeast')}} {{$v.formData.referralCode.$params.minLength.min}} {{$t('siteLang.Digit')}}</span>
                </div>
            </div>
        </div>
        <div class="form-group text-center text-muted my-3 font-size-13">
          {{ $t('siteLang.Yourpersonalinforsafe') }}
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary btn-lg fw-medium" :disabled="loading" @click="register" type="button" id="sign-in-button">
           <span v-if="loading">{{$t('siteLang.Registering')}}</span>
           <span v-else> {{$t('siteLang.Register')}} </span>
           <span class="spinner-border spinner-border-sm ms-2" v-if="loading" role="status"
            aria-hidden="true"></span>
          </button>
          <!-- <button class="btn btn-secondary btn-lg fw-medium" @click="login" type="button">Login</button> -->
        </div>
        <div class="my-3 mb-5 px-0 form-check text-center text-muted">
          <input type="checkbox" class="form-check-input float-none me-2"
          :class="{
            'is-invalid': submitted && $v.formData.agreeTerm.$error,
          }"
          v-model="formData.agreeTerm" id="agree">
          <label class="form-check-label  d-inline fw-normal font-size-13 text-muted" for="agree"> {{ $t('siteLang.IagreedPrivacyPolicy') }}</label>
         <div
            v-if="submitted && $v.formData.agreeTerm.$error"
            class="invalid-feedback font-size-13"
          >
            <span v-if="!$v.formData.agreeTerm.required"
              >{{$t('siteLang.youMustAgree') }}</span
            >
          </div>

        </div>
      </div>
      <div class="col-12" v-show="showVerifyModule">
        <div class="form-group mb-3">
          <div id="lottieDivs" class="m-auto" style="width:320px"></div>
          <h2 class="font-size-18 text-center fw-medium text-primary">Sent to {{formData.phone}}</h2>
          <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"><i class="mdi mdi-cellphone-iphone me-1 font-size-16"></i> Verification Code </label>
          <input type="tel" class="form-control form-control-lg bg-none" placeholder="Verification Code"  oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\+\d{1,2})\s?(\d{3})\s?(\d{4})/, '$1$2$3')" maxlength="6" v-model="otp">

            
        </div>
        <p class="text-center">Didn't get code yet?<br>
            <a href="javascript:void(0)" @click="sendOtp()" :aria-disabled="loading">Resend OTP</a>
            </p>
        <div class="d-grid gap-2">
          <button class="btn btn-primary btn-lg fw-medium" :disabled="verifiering || loading" @click="verifyOtp" type="button">
           <span v-if="verifiering">Verifiering..</span>
           <span v-else> Verify </span>
           <span class="spinner-border spinner-border-sm ms-2" v-if="verifiering" role="status"
            aria-hidden="true"></span>
          </button>
        </div>
      </div>
      <div id="recaptcha-container" class="d-none"></div>
    </div>
    <!-- <Lottie :path="'animate/otp.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" /> -->
    <ModalQRCode ref="modalQRCodeFunc" @returnData="QRcodeReturnData" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import ModalQRCode from "@/components/modalQRcodeScanner";
import { required, minLength, helpers} from "vuelidate/lib/validators";
export const countryCodeAndPhone = (value) => {
  const regex = /(\+)\d{8,15}/
  return helpers.regex('countryCodeAndPhone', regex)(value)
}
import VueCountryCode from "vue-country-code";

import axios from "axios";
import Swal from "sweetalert2";
//https://github.com/devatquarxss/phone-password-authentication-firebase/blob/master/src/components/signup.vue
import firebase from 'firebase/app';
import 'firebase/auth';

/**
 * Starter page
 */
//PageHeader
export default {
  components: { 
    Layout, 
    VueCountryCode,
    //Lottie,
    Common,
    ModalQRCode
  },
  page() {
    return {
      title: this.$t('siteLang.Register'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  watch:{
    $route (to, from){
      console.log(to,from)
    }
  } ,
  data() {
    return {
      selectedLang: {},
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        // {
        //   flag: require("@/assets/images/flags/china.png"),
        //   language: "zh",
        //   title: "Chinese",
        // },
        {
          flag: require("@/assets/images/flags/indonesia.png"),
          language: "id",
          title: "Indonesia",
        },
        
      ],
      loading:false,
      verifiering:false,
      showLottie:false,
      showPassword: false,
      formData:{
        phone:"",
        password:"",
        referralCode:"",
        agreeTerm:false,
      },
      showVerifyModule:false,
      countryCode:'',
      phNo: '',
      appVerifier: '',
      otp: '',
      submitted: false,
      title: '',
      items: [
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
    };
  },
  validations: {
    formData:{
      phone: {
        required,
        countryCodeAndPhone,
        minLength: minLength(10)
      },
      password: {
        required,
      },
      referralCode: {
        required,
        minLength: minLength(6)
      },
      agreeTerm: {
        checked: value => value === true,
      }
    
    }
   
  },
  computed: {
    passwordFieldType() {
      return this.showPassword ? 'text' : 'password'
    }
  },
  mounted(){
    this.$refs.main.changeVerticalTopBar("login",false)
    if (this.$refs.commonFunc.getSetLang() ==undefined){
      this.$refs.commonFunc.setLang('id')
    }
    const Language = this.languages.find(lang => lang.language === this.$i18n.locale);
    this.selectedLang = Language

    this.title = this.$t('siteLang.Register')
    this.items[1].text = this.$t('siteLang.Register') 
    this.showLottieLocal('lottieDivs','animate/enterpassword.json',true)
   
  },
  created(){
    this.initReCaptcha()
  },
  methods:{
    async scanQR () {
      this.$refs.modalQRCodeFunc.scanQR();
    },
    QRcodeReturnData(data){
      this.formData.referralCode = data
    },
    showLottieLocal(element,path,loop){
      var svgContainer = document.getElementById(element);
      var animation = window.bodymovin.loadAnimation({
        container: svgContainer,
        renderer: 'svg',
        loop: loop,
        autoplay: true,
        path: path
      })
      animation.play()      
    },
    onSelect(e){
      console.log(e)
      this.countryCode = '+'+ e.dialCode
    },
    phoneChange(){
      this.formData.phone = this.countryCode + this.phNo
    },
    sendOtp(){
        this.register()
    },
    verifyOtp(){
        //
        this.$Progress.start();
        this.verifiering = true
        let code = this.otp
        //
        if (window.confirmationResult !== undefined) {
          window.confirmationResult.confirm(code).then((result)=>  {
            // User signed in successfully.
            console.log(result)
            var user = result.user;
            console.log(`Phone No. ${user.phoneNumber}`)
            console.log(`User UID. ${user.uid}`)
            indexedDB.deleteDatabase('firebaseLocalStorageDb');
            
            var bodyFormData = new FormData();
            bodyFormData.append("phone",this.formData.phone);
            bodyFormData.append("password",this.formData.password);
            bodyFormData.append("referralCode",this.formData.referralCode);
            bodyFormData.append("lang",this.selectedLang.language);
            bodyFormData.append("uid", user.uid);
            axios({
                method: "post",
                url: "https://www.seveninblank.com/projects/api/anythings.php",
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response)=>{
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  Swal.fire(
                  {
                    icon: 'success',
                    title: 'Thank you for registering!',
                    html: `Your account has been created successfully.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  }).then((result) => {
                    console.log(result);
                    this.showVerifyModule = false
                    this.login()
                  })
                } else {
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#222',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                    this.$Progress.fail();
                }
                this.verifiering = false;
                this.$Progress.finish();
            })
            .catch((error)=> {
                this.verifiering = false;
                this.$Progress.finish();
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
             });
            // ...
            //route to set password !
          }).catch((error)=> {
            console.log(error.message);
            this.verifiering = false;
            this.$Progress.finish();
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error.message}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
          });
        }else{
          this.verifiering = false;
          this.$Progress.finish();
          Swal.fire(
            {
            icon: 'error',
            title: 'Oops...',
            html: `You're not yet click the get otp button`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }
    },
    initReCaptcha(){
      setTimeout(()=>{
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
          'callback': function(response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
            console.log("initReCaptcha"+response);
          },
          'expired-callback': function() {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          }
        });
        //
        this.appVerifier = window.recaptchaVerifier
      },1000)
    },
    togglePasswordFieldType() {
      this.showPassword = !this.showPassword
    },
    register(){
      this.submitted = true
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.loading = true
        let phoneNumber = this.countryCode +this.phNo
        let appVerifier = this.appVerifier
        //
        var self = this;
        console.log("phoneNumber"+ phoneNumber)
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult)=> {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            //
            self.showVerifyModule=true
            self.$Progress.finish();
            self.loading = false
          }).catch((error)=> {
            // Error; SMS not sent
            console.log(error.message)
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: error.message,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
            self.$Progress.finish();
            self.loading = false
          });
      }
    },
   
    login(){
      this.$router.push({ name: 'login'})
    },
    setLanguage(value) {
      this.selectedLang = value
      this.$refs.commonFunc.setLang(value.language)
      this.$refs.commonFunc.getSetLang()
    },
  },
  middleware: "authentication",
};
</script>
<style>
.vue-country-select .dropdown:hover{
  background-color: rgba(0,0,0,0.0) !important;
}
.vue-country-select .dropdown-list{
  width: 300px !important;
  overflow-x:hidden
}
.vue-country-select{
  box-shadow: none !important;
  -webkit-border-top-left-radius: 0.4rem !important;
  -webkit-border-bottom-left-radius: 0.4rem !important;
  -moz-border-radius-topleft: 0.4rem !important;
  -moz-border-radius-bottomleft: 0.4rem !important;
  border-top-left-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important;
  border: 1px solid #ced4da !important;
}
.vue-country-select .dropdown{
  padding: 0px 5px;
  -webkit-border-top-left-radius: 0.4rem !important;
  -webkit-border-bottom-left-radius: 0.4rem !important;
  -moz-border-radius-topleft: 0.4rem !important;
  -moz-border-radius-bottomleft: 0.4rem !important;
  border-top-left-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important;
  background: rgba(0,0,0,0.) !important;
}
.vue-country-select .dropdown strong {
  font-weight: 500 !important;
}
.vue-country-select .dropdown:hover{
  background: rgba(0,0,0,0.) !important;
}
.vue-country-select .country-code{color: #444 !important; margin-right: 5px;}
.vue-country-select .current{font-size: 17px !important;}
.vue-country-select .dropdown-arrow{font-size: 11px !important;}
.qrcode-stream-camera{
  border-radius: 5px;
}
</style>
<style scoped>
.h-0{height: 0px;}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
   border-radius:5px
}
.scanning {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  border-radius:5px
}
.bg-none{
  background: none;
}
.form-check-input[type=checkbox] {
  width: 1.2em;
  height: 1.2em;
  margin-top: 3px;
  margin-left: 0;
  margin-right: 0;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #ced4da;
  display: inline-block;
  background-position: 0px;
}

.form-check-input[type=checkbox]:checked {
  background-color:#04DA8C!important ;
  border-color: #04DA8C!important ;
}

</style>