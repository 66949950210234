<template>
  <div>
      <b-modal
      @hidden="resetModal"
      ref="modal-withdraw"
      centered
      hide-footer
      hide-header
      content-class="bg-white border-white overflow-hidden"
    >
      <div class="row align-items-center">
        <div class="col text-primary mb-2" >
        <h3 class="text-primary animate__animated animate__slideInDown text-center py-2">
          <i class="mdi mdi-qrcode-scan font-size-22"></i> Scan QR Code
        </h3>
        </div>
        <div class="col-12">
          <div class="rounded p-0 mb-0 animate__animated animate__slideInUp" >
            <div class="d-block text-center" v-if="qrLoading">
              <div id="lottieDivs2" class="m-auto"></div>
            </div>
            <qrcode-stream :camera="camera" @init="onInit" @decode="onDecode" :track="selected.value" :class="{'h-0':qrLoading}">
              <div v-show="!showScanConfirmation" class="scanning">
                <div id="lottieDivs4" class="m-auto" ></div>
              </div>
              <div v-show="showScanConfirmation" class="scan-confirmation">
                <div id="lottieDivs3" class="m-auto" style="width:200px;"></div>
              </div>
            </qrcode-stream>
          </div>

          <!-- <div class="text-primary" v-if="!qrLoading">
            <p class="text-center text-body mt-3 d-block font-size-11">Place a barcode inside the viewfinder rectangle to scan it</p>
            <div class="mb-0 border-top pt-3">
               <label for="formFile" class="form-label"><i class="mdi mdi-camera"></i> Camera Capture</label>
               <qrcode-capture class="form-control" @init="onDetect" @detect="onDetect"></qrcode-capture></div>
           </div> -->
        </div>
        
      </div>
    </b-modal>
    </div>
</template>
<script>
import Swal from "sweetalert2";
/**
 * Starter page
 */
//PageHeader
export default {
  components: { 
  },
  watch:{
    $route (to, from){
      console.log(to,from)
    }
  } ,
  data() {
    return {
      options:[
        { text: "nothing (default)", value: undefined },
        { text: "outline", value: this.paintOutline },
        { text: "centered text", value: this.paintCenterText },
        { text: "bounding box", value: this.paintBoundingBox },
      ],
      selected:{},
      decodedString:"",
      qrLoading:false,
      showScanConfirmation: false,
      camera:"rear",
    };
  },
  
  mounted(){
   
  },
  created(){
    this.selected = this.options[3]
  },
  methods:{
    async onInit (promise) {
      this.qrLoading = true
      try {
        await promise
        this.showLottieLocal('lottieDivs4','animate/green-scan.json',true)
      } catch (error) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
        this.$refs['modal-withdraw'].hide()
      } finally {
        this.qrLoading = false
        this.showScanConfirmation = this.camera === "off"
      }
    },

    async scanQR () {
      this.$refs['modal-withdraw'].show()
      setTimeout(() => {
        this.showLottieLocal('lottieDivs2','animate/scan-and-pay2.json',true)
      },0)
    },
    resetModal(){
      console.log("resetModal")
      this.camera = 'auto'
    },
    paintOutline (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [ firstPoint, ...otherPoints ] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },

    paintBoundingBox (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox: { x, y, width, height } } = detectedCode
        ctx.lineWidth = 2
        ctx.strokeStyle = '#04DA8C'
        ctx.strokeRect(x, y, width, height)
      }
    },

    paintCenterText (detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode

        const centerX = boundingBox.x + boundingBox.width/ 2
        const centerY = boundingBox.y + boundingBox.height/ 2

        const fontSize = Math.max(12, 50 * boundingBox.width/ctx.canvas.width)
        console.log(boundingBox.width, ctx.canvas.width)

        ctx.font = `bold ${fontSize}px sans-serif`
        ctx.textAlign = "center"

        ctx.lineWidth = 3
        ctx.strokeStyle = '#04DA8C'
        ctx.strokeText(detectedCode.rawValue, centerX, centerY)

        ctx.fillStyle = '#04DA8C'
        ctx.fillText(rawValue, centerX, centerY)
      }
    },
    async onDecode(decodedString) {
      console.log(decodedString)
      this.$emit('returnData',decodedString)
      this.camera = 'off'
      setTimeout(() => {
        this.showLottieLocal('lottieDivs3','animate/scan-payment-confirmation.json',false)
      },0)
      await this.timeout(1500)
      this.$refs['modal-withdraw'].hide()
      this.camera = 'auto'
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    async onDetect (promise) {
      console.log(promise)
      try {
        const {
          imageData,    // raw image data of image/frame
          content,      // decoded String or null
          location      // QR code coordinates or null
        } = await promise

        if (content === null) {
          // decoded nothing
          console.log("nothing")
        } else {
          console.log(imageData)
          console.log(content)
          console.log(location)
          // ...
        }
      } catch (error) {
        console.log(error)
        // ...
      }
    },
    showLottieLocal(element,path,loop){
      var svgContainer = document.getElementById(element);
      var animation = window.bodymovin.loadAnimation({
        container: svgContainer,
        renderer: 'svg',
        loop: loop,
        autoplay: true,
        path: path
      })
      animation.play()      
    }
  },
};
</script>
<style>
.qrcode-stream-camera{
  border-radius: 5px;
}
</style>
<style scoped>
.h-0{height: 0px;}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
   border-radius:5px
}
.scanning {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  border-radius:5px
}
</style>